//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MessageTextLeft from "@/components/Session/module/chat/MessageTextLeft"
import MessageTextRight from "@/components/Session/module/chat/MessageTextRight"
import MessageImageRight from "@/components/Session/module/chat/MessageImageRight"
import MessageSystemText from "@/components/Session/module/chat/MessageSystemText"

import {
  http_session_account_list,
  http_session_chatlog_list,
  http_session_send_text,
} from "@/api/session.js"
import {http_user_table_list} from "@/api/user.js"

export default {

  name: "Index",
  components: {
    MessageTextLeft, MessageTextRight, MessageImageRight, MessageSystemText
  },
  mounted() {
    this.$nextTick(function () {

      this.request_http_session_account_list();
      this.upload_url = this.upload_url + '/session/send/image';
      this.headers.authorization = localStorage.getItem("authorization");

    });
  },
  data() {
    return {
      search: {
        nickname: '',
        opntion: '',
        cust_input: '',
        customer_status: '',
        entertime: '',
      },
      account_page: 1,
      account_page_size: 300,
      page: 1,
      page_size: 20,
      count: 0,
      account_list: [],
      active_index: -1,
      merchant_info: {},
      tableData: [],
      dialogImVisible:false,
      title:"",
      chat_list:[],
      sendText:"",
      user_info:{},
      upload_url: "https://dy.yskcrm.com",
      headers: {},
      upload_data: {},
      file_name: "msg_file",
      loading:false,
      fileList: [],
    }
  },

  methods: {

    beforeUpload(file) {
      let ghid = this.user_info.ghid;
      let open_id = this.user_info.open_id;
      this.upload_data.open_id = open_id;
      this.upload_data.ghid = ghid;
      console.log(this.upload_data);
      console.log(file);
      console.log("文件上传之前的准备");
      this.loading = true;
    },
    onUploadSuccess(res, file, fileList){
      console.log("文件上传成功");
      console.log(res);
      console.log(file);
      console.log(fileList);
      this.loading = false;
      let _this = this;
      this.fileList = [];
      if(res.code==0){
        this.chat_list.push(res.data);
      }else{
        this.$message.error(res.message);
      }

      setTimeout(function () {
        let container = _this.$el.querySelector("#chatbody");
        container.scrollTop = container.scrollHeight;
      }, 1200);
    },

    /**
     * 加载抖音账号列表
     */
    request_http_session_account_list() {
      http_session_account_list({page: this.account_page, page_size: this.account_page_size}).then(res => {
        if (res.code == 0) {
          let data = res.data.data;
          this.account_list = data;
          this.count = res.data.count;
          this.$store.commit('set_session_merchant_list', res.data.data);
          if (this.active_index == -1 && data.length > 0) {
            this.on_select_account(data[0], 0);
            this.merchant_info = data[0];
            this.request_http_user_table_list();
            this.active_index = 0;
          }
        }
      })
    },

    request_http_user_table_list() {
      let data = {
        search: this.search,
        page: this.page,
        page_size: this.page_size,
        open_id: this.merchant_info.open_id
      };
      console.log(data);
      http_user_table_list(data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },

    request_http_session_chatlog_list(row){
      let ghid = row.ghid;
      let open_id = row.open_id;
      http_session_chatlog_list({page: 1, page_size: 25, ghid: ghid, open_id: open_id}).then(res=>{
        if(res.code==0){
          this.chat_list = res.data;
          console.log(this.chat_list)
        }
      })
    },

    on_select_account(row, index) {
      this.merchant_info = row;
      this.active_index = index;
      this.$store.commit('set_session_merchant_item', row);
      this.page = 1;
      this.request_http_user_table_list();
    },


    onChangePage(page) {
      this.page = page;
      this.request_http_user_table_list();
    },

    /**
     * 提交搜索
     */
    onSubmitSearch() {
      this.page = 1;
      this.request_http_user_table_list();
    },
    imChat(row,index){
      let _this = this;
      this.dialogImVisible = true;
      this.title = "与 【"+row.nickname+"】 进行沟通";
      console.log(index);
      this.user_info = row;
      this.request_http_session_chatlog_list(row);
      setTimeout(function () {
        let container = _this.$el.querySelector("#chatbody");
        container.scrollTop = container.scrollHeight;
      }, 1200);
    },
    sendMessage(){
      let _this = this;
      let ghid = this.user_info.ghid;
      let open_id = this.user_info.open_id;
      let message = {
        message: this.sendText, ghid, open_id
      };
      this.sendText = "";
      http_session_send_text(message).then(res => {
        if (res.code == 0) {
          //this.$store.commit("push_session_chat_content", res.data);
          this.chat_list.push(res.data);
        }else{
          this.$message.error(res.message);
        }
        setTimeout(function () {
          let container = _this.$el.querySelector("#chatbody");
          container.scrollTop = container.scrollHeight;
        }, 1200);
      })
    }
  },
  watch:{
    '$store.state.session_chat_new_message_customer': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      let _this = this;
      if(newValue.open_id == this.user_info.open_id && newValue.ghid == this.user_info.ghid){
        this.chat_list.push(newValue);
        setTimeout(function () {
          let container = _this.$el.querySelector("#chatbody");
          container.scrollTop = container.scrollHeight;
        }, 1200);
      }
    }
  }

}
