//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MessageImageRight",
  data(){
    return {
    }
  },
  methods:{
    showInfoImage(){

    }
  },
  props:["msgData"]
}
