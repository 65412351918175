//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MessageText",
  props:["msgData"]
}
