//
//
//
//
//
//
//
//

export default {
  name: "MessageSystemText",
  props:["msgData"]
}
